import React from 'react';
import { ButtonProps } from '@mui/material';
import './continueButton.scss';
import { ArrowBack } from '@mui/icons-material';

const ContinueButton: React.FC<ButtonProps> = ({ ...props }: ButtonProps) => {
  return (
    <button className="continue-button" {...props}>
      Continuer
      <ArrowBack className="continue-button__arrow" />
    </button>
  );
};

export default ContinueButton;
