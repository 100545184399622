import React from 'react';
import { useTranslation } from 'react-i18next';
import './privacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation('components/questions');

  const handleOpenLink = () => {
    window.open('https://www.kleep.ai/legal', '_blank');
  };

  return (
    <div className="privacy-policy">
      <p>{t('pp_part1')}</p> <p onClick={handleOpenLink}>{t('pp_link')}</p>
    </div>
  );
};

export default PrivacyPolicy;
