import React from 'react';
import './code.scss';

interface Props {
  nextStep(): void;
  handleNoCode(): void;
}

const StepCode: React.FC<Props> = ({ handleNoCode, nextStep }: Props) => {
  return (
    <div className="code">
      <div>
        <p className="code__title">
          Trouvez votre taille idéale pour tous nos articles.
        </p>
      </div>
      <div>
        <div>
          <button onClick={nextStep} className="code__button">
            J’ai un code Entreprise
          </button>
        </div>
        <div>
          <p onClick={handleNoCode} className="code__no-code">
            Je n’ai pas de code
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepCode;
