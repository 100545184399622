import React, { FC, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '../../molecules/Input/Text';

import './desktop.scss';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import InputWithRadioButtons from '../../molecules/InputWithRadioButtons';
import UserInfo from '../../userInfo';
import PrivacyPolicy from '../../molecules/PrivacyPolicy';

interface IPropsQuestionsDesktop {
  object?: any;
  nextStep: () => void;
  setIsShowAnimation(value: boolean): void;
  isShowAnimation: boolean;
}

const QuestionsDesktop: FC<IPropsQuestionsDesktop> = ({
  object,
  nextStep,
  isShowAnimation,
  setIsShowAnimation
}) => {
  const { t } = useTranslation('components/questions');

  const [transformValue, setTransformValue] = useState(
    isShowAnimation ? 'translateX(101%)' : 'translateX(0%)'
  );
  const [opacity, setOpactity] = useState(isShowAnimation ? 0 : 1);
  const [disabled, setDisabled] = useState(
    object.reduce((acc: any, { name }: keyof object) => {
      acc[name] = false;
      return acc;
    }, {})
  );

  useEffect(() => {
    if (isShowAnimation) {
      requestAnimationFrame(() => {
        setTransformValue('translateX(0%)');
      });
      setIsShowAnimation(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpactity(1);
    }, 800);
  }, []);

  const handleNext = () => {
    const previousUser = getLocalAndParse('user');
    console.log('previousUser', previousUser);
    const newInputs = object.reduce((acc: any, { name, inputRef }: any) => {
      acc[name] = inputRef.current;
      return acc;
    }, {});
    const newUser = { ...previousUser, ...newInputs };
    localStorage.setItem('user', JSON.stringify(newUser));
    nextStep();
  };

  return (
    <div className="questions-desktop">
      <div className="questions-desktop__block" />
      <div
        className="questions-desktop__container"
        style={{
          transform: transformValue
        }}
      >
        <div
          className="questions-desktop__container__content"
          style={{
            opacity: opacity
          }}
        >
          <h1 className="questions-desktop__container__content__title">
            <Trans
              ns="components/questions"
              i18nKey="title"
              values={{ separator: '\n' }}
            />
          </h1>
          {Object.keys(object).map((idx) => {
            return object[idx].unitVariants ? (
              <InputWithRadioButtons
                key={idx}
                object={object[idx]}
                setDisabled={setDisabled}
              />
            ) : (
              <Text key={idx} object={object[idx]} setDisabled={setDisabled} />
            );
          })}
          <button
            onClick={handleNext}
            className="questions-desktop__container__content__button"
            disabled={Object.values(disabled).some((value) => value === true)}
          >
            {t('continue')}
          </button>
          <div style={{ marginTop: '30px' }}>
            <PrivacyPolicy />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionsDesktop;
