import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './mobile.scss';
import { Dialog, DialogContent } from '@mui/material';

import GppGoodIcon from '@mui/icons-material/GppGood';

interface IPropsQrcodeMobile {
  showSkip?: boolean;
  skipScan?: () => void;
  loading?: boolean;
  url?: string;
  gender: string | undefined;
}

const QrcodeMobile: FC<IPropsQrcodeMobile> = ({
  showSkip,
  skipScan,
  loading,
  url,
  gender
}) => {
  const { t } = useTranslation('components/qrcode/mobile');
  const [helpDialog, setHelpDialog] = useState(false);

  const openScan = () => {
    if (!loading) {
      window.open(url, '_blank');
    }
  };

  return (
    <div className="qrcode_mobile">
      <div className="qrcode_mobile__text">
        {<p className="qrcode_mobile__text__title">{t('title')}</p>}
        <p className="qrcode_mobile__text__description">{t('description')}</p>
      </div>
      <video muted className="qrcode_mobile__video" playsInline autoPlay loop>
        <source
          src={
            gender === 'male'
              ? require('../../../../assets/qrcode/demo-male.mp4')
              : require('../../../../assets/qrcode/demo-female.mp4')
          }
          type="video/mp4"
        />
      </video>
      <div className="qrcode_mobile__safety">
        {<GppGoodIcon sx={{ fontSize: '20px', marginRight: '5px' }} />}
        {t('safety')}
      </div>
      <div className="qrcode_mobile__body">
        {false && (
          <button
            className="qrcode_mobile__body__help"
            onClick={() => setHelpDialog(true)}
          >
            <img
              className="qrcode_mobile__body__help__icon"
              src={require('../../../../assets/help.png')}
            />
            {t('help.title')}
          </button>
        )}
        {false && (
          <Dialog
            className="qrcode_mobile__body__help_dialog"
            open={helpDialog}
            onClose={() => setHelpDialog(false)}
          >
            <DialogContent>
              <div className="qrcode_mobile__body__help_dialog__content">
                <p className="qrcode_mobile__body__help_dialog__content__title">
                  {t('help.title')}
                </p>
                <p className="qrcode_mobile__body__help_dialog__content__description">
                  {t('help.description')}
                </p>
                <a
                  target="_blank"
                  href="https://www.kleep.ai/legal"
                  className="qrcode_mobile__body__help_dialog__content__button"
                  rel="noreferrer"
                >
                  {t('help.privacyPolicy')}
                </a>
              </div>
            </DialogContent>
          </Dialog>
        )}
        <button className="qrcode_mobile__body__start" onClick={openScan}>
          {t('buttons.scan')}
        </button>
        <button className="qrcode_mobile__body__skip" onClick={skipScan}>
          {t('buttons.skip')}
        </button>
      </div>
    </div>
  );
};

export default QrcodeMobile;
