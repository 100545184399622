import { SaveUserInfoBody, SaveUserInfoParams } from '../Types/SaveUserInfo';
import { API_ENDPOINT } from '../utils';

export const getConfig = async (domain: string | null) => {
  const response = await fetch(`${API_ENDPOINT}modal/get-config`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      retailer: domain
    })
  });
  const data = await response.json();
  console.log('data', data);
  return data;
};

export const newUser = async (domain: string | null) => {
  localStorage.setItem('domain', domain || '');
  const uid = await fetch(`${API_ENDPOINT}new-user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      retailer: domain,
      device_id: 'abcdefghiju'
    })
  })
    .then((res) => res.json())
    .then((res) => res['user_id'])
    .catch((e) => {
      console.log('Error: newUser - ', e);
      return false;
    });

  if (uid) {
    localStorage.setItem('uid', uid);
    return uid;
  } else {
    return false;
  }
};

export const newMeasure = async (measure_type: string, user_id?: string) => {
  const uid = localStorage.getItem('uid') || user_id || null;
  if (uid) {
    const mid = await fetch(`${API_ENDPOINT}new-measure`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: uid,
        measure_type: measure_type
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const resMid = res['measure_id'];
        window.parent.postMessage({ data: 'mid', mid: resMid }, '*');
        return resMid;
      })
      .catch((e) => {
        console.log('Error: newMeasure to see - ', e);
        return false;
      });

    if (mid) {
      localStorage.setItem('mid', mid);
      return mid;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const questionPushAnswersAndTrigger = async (answers: object) => {
  console.log('questionPushAnswersAndTrigger');
  const mid = localStorage.getItem('mid') || null;
  const body = {
    measure_id: mid,
    answers: answers
  };
  if (mid) {
    console.log('questionPushAnswersAndTrigger', body);
    const success = await fetch(`${API_ENDPOINT}question-push-trigger`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((res) => res.json())
      .then((res) => res)
      .catch((e) => {
        console.log('Error: questionPushAnswersAndTrigger - ', e);
        return false;
      });

    return !!success;
  } else {
    return false;
  }
};

export const scanPushAnswers = async (answers: object) => {
  console.log('scanPushAnswers');
  const mid = localStorage.getItem('mid') || null;
  if (mid) {
    const success = await fetch(`${API_ENDPOINT}scan-push-answers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        measure_id: mid,
        answers: answers
      })
    })
      .then((res) => res.json())
      .then((res) => res)
      .catch((e) => {
        console.log('Error: scanPushAnswers - ', e);
        return false;
      });
    return success;
  } else {
    return false;
  }
};

export const scanCheckMeasures = async () => {
  const mid = localStorage.getItem('mid') || null;
  if (mid) {
    const success = await fetch(`${API_ENDPOINT}scan-check-measurements`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        measure_id: mid
      })
    })
      .then((res) => res.json())
      .then((res) => res['completed'])
      .catch((e) => {
        console.log('Error: scanCheckMeasures - ', e);
        return false;
      });
    return success;
  } else {
    return false;
  }
};

export const recommend = async (pid: string | null) => {
  const mid = localStorage.getItem('mid') || null;
  if (mid) {
    const success = await fetch(`${API_ENDPOINT}recommend?version=1.0.4`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        measure_public_id: mid,
        product_reference: pid
      })
    })
      .then((res) => res.json())
      .then((res) => {
        if (res?.recommendation) {
          return res?.recommendation;
        } else {
          return null;
        }
      })
      .catch((e) => {
        console.log('Error: recommend - ', e);
        return false;
      });
    return success;
  } else {
    return false;
  }
};

export const scanCheckMeasurements = async () => {
  const mid = localStorage.getItem('mid') || null;
  if (mid) {
    const success = await fetch(`${API_ENDPOINT}scan-check-measurements`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        measure_id: mid
      })
    })
      .then((res) => res.json())
      .then((res) => res['completed'])
      .catch((e) => {
        console.log('Error: scanCheckMeasurements - ', e);
        return false;
      });
    return success;
  } else {
    return false;
  }
};

export const similarProducts = async (pid?: string) => {
  if (pid) {
    const result = await fetch(`${API_ENDPOINT}similar-products`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        product_reference: pid,
        retailer: 'getkleep-preprod.fr'
      })
    })
      .then((res) => res.json())
      .catch((e) => {
        console.log('Error: similar-products - ', e);
        return false;
      });
    return result;
  }
};

export const saveUserInfo = async (params: SaveUserInfoBody) => {
  const mid = localStorage.getItem('mid') || null;
  const uid = localStorage.getItem('uid') || null;
  const result = await fetch(`${API_ENDPOINT}save-user-info`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user_id: uid,
      measure_id: mid,
      body: params
    })
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log('Error: save-user-data - ', e);
      return false;
    });

  return result;
};
