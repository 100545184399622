import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './result.scss';
import { useAppContext } from '../../../context/AppContext';

import { useMediaQuery } from 'react-responsive';

import banner from '../../../assets/intro/banner.png';
import main_marcy from '../../../assets/result/main_marcy.png';

interface IPropsResult {
  nextStep: () => void;
  restart: () => void;
  reducedResult?: any;
  focused: number;
  setFocused: (key: number) => void;
  product: any;
  productFeedback: any;
  fitFeedback: any;
  colors: any;
  feedbacks: any;
}

const closeIframe = () => {
  window.parent.postMessage(
    { data: 'mid', mid: localStorage.getItem('mid') },
    '*'
  );
  window.parent.postMessage({ action: 'closeIframe' }, '*');
};
const sendMid = () =>
  window.parent.postMessage(
    { data: 'mid', mid: localStorage.getItem('mid') },
    '*'
  );

const Result: FC<IPropsResult> = ({
  nextStep,
  restart,
  reducedResult,
  focused,
  setFocused,
  product,
  productFeedback,
  fitFeedback,
  feedbacks,
  colors
}) => {
  if (!reducedResult) {
    return null;
  }

  const { t } = useTranslation('components/results/result');
  const isSmallMobile = useMediaQuery({ maxWidth: 376 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const initCentersAndDeltas = (isMobile: boolean) => {
    return {
      centers: {
        middle: 58,
        left: isMobile ? 65 : 62,
        right: isMobile ? 50 : 54
      },
      deltas: {
        middle: 0,
        left: isMobile ? -14 : -7,
        right: isMobile ? 14 : 7
      }
    };
  };

  const [margins, setMargins] = useState(initCentersAndDeltas(isMobile));

  useEffect(() => {
    sendMid();
  }, []);

  const cuts_template = ['Fitted', 'Regular', 'Oversize'];

  const initCenter = () => {
    const possibleResults = Object.keys(reducedResult).reduce(
      (acc: any, key) => {
        if (reducedResult[key].possible) {
          acc[key] = reducedResult[key];
        }
        return acc;
      },
      {}
    );
    const sum = Object.keys(possibleResults).reduce(
      (acc: any, current: any) => acc + Number(current),
      0
    );
    console.log('sum', Object.keys(possibleResults).length);

    const average = sum / Object.keys(possibleResults).length;
    if (average === 1.5) return margins.centers.right;
    if (average === 0.5) return margins.centers.left;
    else return margins.centers.middle;
  };

  const initDelta = () => {
    if (focused === 2) return margins.deltas.left;
    if (focused === 0) return margins.deltas.right;
    else return margins.deltas.middle;
  };

  const handleResize = () => {
    initCenter();
    initDelta();
    setMargins(initCentersAndDeltas(isMobile));
  };

  const [initMargin, setInitMargin] = useState(initCenter());
  const [deltaMargin, setDeltaMargin] = useState(initDelta());

  useEffect(() => {
    console.log('reducedResult', reducedResult);
  }, [reducedResult]);

  useEffect(() => {
    setInitMargin(initCenter());
  }, [reducedResult]);

  useEffect(() => {
    setDeltaMargin(initDelta());
  }, [focused]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const appContext: any = useAppContext() || '';
  const product_id = appContext.pid;

  const Fits = ({ style }: any) => {
    return (
      <div style={{ ...style }}>
        <div
          style={{
            width: isMobile ? '60%' : '100%',
            height: isMobile ? '90%' : '110%',
            pointerEvents: 'none',
            backgroundImage: `url(${product?.local ?? main_marcy})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <div
          style={{
            position: 'absolute',
            zIndex: 99999,
            height: isMobile ? '40%' : '60%',
            width: isMobile ? '25%' : '15%',
            marginLeft: isMobile ? '20%' : '26%'
          }}
        >
          {productFeedback?.chest[0] !== undefined && colors?.chest && (
            <>
              <div
                style={{
                  position: 'absolute',
                  marginTop: '65%',
                  marginLeft: '6%',
                  height: isMobile ? 'auto' : '20px',
                  backgroundColor: colors.chest,
                  transition: 'all 0.5s',
                  borderRadius: '3px',
                  display: 'flex',
                  padding: '0px 5px',
                  fontSize: '12px'
                }}
              >
                {productFeedback?.chest !== undefined &&
                feedbacks?.chest !== undefined &&
                productFeedback?.chest[feedbacks?.chest] !== undefined
                  ? fitFeedback[feedbacks.chest][
                      productFeedback.chest[feedbacks.chest]
                    ]
                  : ''}
              </div>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 99999,
                  marginTop: '67.5%',
                  transition: 'all 0.5s',
                  width: 0,
                  height: 0,
                  borderTop: '7px solid transparent',
                  borderBottom: '7px solid transparent',
                  borderRight: `7px solid ${colors.chest}`
                }}
              />
            </>
          )}
          {productFeedback?.waist[0] !== undefined && colors?.waist && (
            <>
              <div
                style={{
                  position: 'absolute',
                  marginTop: '118%',
                  marginLeft: '6%',
                  height: isMobile ? 'auto' : '20px',
                  backgroundColor: colors.waist,
                  transition: 'all 0.5s',
                  borderRadius: '3px',
                  display: 'flex',
                  padding: '0px 5px',
                  fontSize: '12px'
                }}
              >
                {productFeedback?.waist !== undefined &&
                feedbacks?.waist !== undefined &&
                productFeedback?.waist[feedbacks?.waist] !== undefined
                  ? fitFeedback[feedbacks.waist][
                      productFeedback.waist[feedbacks.waist]
                    ]
                  : ''}
              </div>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 99999,
                  marginTop: '120%',
                  transition: 'all 0.5s',
                  width: 0,
                  height: 0,
                  borderTop: '7px solid transparent',
                  borderBottom: '7px solid transparent',
                  borderRight: `7px solid ${colors.waist}`
                }}
              />
            </>
          )}
          {productFeedback?.trouser_waist[0] !== undefined &&
            colors?.trouser_waist && (
              <>
                <div
                  style={{
                    position: 'absolute',
                    marginTop: '140%',
                    marginLeft: '6%',
                    height: isMobile ? 'auto' : '20px',
                    backgroundColor: colors.trouser_waist,
                    transition: 'all 0.5s',
                    borderRadius: '3px',
                    display: 'flex',
                    padding: '0px 5px',
                    fontSize: '12px'
                  }}
                >
                  {productFeedback?.trouser_waist !== undefined &&
                  feedbacks?.trouser_waist !== undefined &&
                  productFeedback?.trouser_waist[feedbacks?.trouser_waist] !==
                    undefined
                    ? fitFeedback[feedbacks.trouser_waist][
                        productFeedback.trouser_waist[feedbacks.trouser_waist]
                      ]
                    : ''}
                </div>
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 99999,
                    marginTop: '142.5%',
                    transition: 'all 0.5s',
                    width: 0,
                    height: 0,
                    borderTop: '7px solid transparent',
                    borderBottom: '7px solid transparent',
                    borderRight: `7px solid ${colors.trouser_waist}`
                  }}
                />
              </>
            )}
          {productFeedback?.hip[0] !== undefined && colors?.hip && (
            <>
              <div
                style={{
                  position: 'absolute',
                  marginTop: '175%',
                  marginLeft: '6%',
                  height: isMobile ? 'auto' : '20px',
                  backgroundColor: colors.hip,
                  transition: 'all 0.5s',
                  borderRadius: '3px',
                  display: 'flex',
                  padding: '0px 5px',
                  fontSize: '12px'
                }}
              >
                {productFeedback?.hip !== undefined &&
                feedbacks?.hip !== undefined &&
                productFeedback?.hip[feedbacks?.hip] !== undefined
                  ? fitFeedback[feedbacks.hip][
                      productFeedback?.hip[feedbacks.hip]
                    ]
                  : ''}
              </div>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 99999,
                  marginTop: '177.5%',
                  transition: 'all 0.5s',
                  width: 0,
                  height: 0,
                  borderTop: '7px solid transparent',
                  borderBottom: '7px solid transparent',
                  borderRight: `7px solid ${colors.hip}`
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="result">
      {!isMobile && (
        <Fits
          style={{
            height: '100%',
            width: '40%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FBFBFB'
          }}
        />
      )}
      <div
        style={{
          width: isMobile ? '100%' : '60%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            width: isMobile ? '100%' : '80%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className="result__size">
            {[0, 1, 2].map((val, idx) => {
              if (Object.keys(reducedResult).includes(idx.toString())) {
                return (
                  <div
                    key={idx}
                    onClick={() => setFocused(idx)}
                    className={`result__size__label result__size__label--${
                      focused === idx ? 'focused' : 'notfocused'
                    } result__size__label--${
                      idx === 1 ? 'centered' : idx === 0 ? 'started' : 'ended'
                    }`}
                  >
                    {reducedResult[idx].label}
                  </div>
                );
              } else {
                return (
                  <div
                    key={idx}
                    onClick={() => setFocused(idx)}
                    className={`result__size__label result__size__label--notfocused result__size__label--${
                      idx === 1 ? 'centered' : idx === 0 ? 'started' : 'ended'
                    }`}
                  />
                );
              }
            })}
          </div>
          <div className="result__gradation">
            {[1, 2, 3].map((val, idx) => (
              <div
                key={idx}
                className="result__gradation__box"
                style={{
                  borderRight: val <= 2 ? '1px #A4A4A4 solid' : undefined
                }}
              />
            ))}
            <div
              className="result__gradation__gradient"
              style={{
                marginLeft: `-${initMargin + deltaMargin}%`,
                opacity:
                  (initMargin === margins.centers.right && focused === 0) ||
                  (initMargin === margins.centers.left && focused === 2)
                    ? 1
                    : 0,
                background:
                  'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(232, 93, 36) 25%, rgb(232, 93, 36) 75%, rgba(255,255,255,1) 100%)'
              }}
            />
            <div
              className="result__gradation__gradient"
              style={{
                marginLeft: `-${initMargin + deltaMargin}%`,
                opacity:
                  (initMargin !== margins.centers.right || focused !== 0) &&
                  (initMargin !== margins.centers.left || focused !== 2)
                    ? 1
                    : 0,
                background:
                  'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(11,121,9,1) 25%, rgba(11,121,9,1) 75%, rgba(255,255,255,1) 100%)'
              }}
            />
          </div>
          <div className="result__preference">
            {cuts_template.map((cut, idx) => (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <div
                  onClick={() => {
                    if (Object.keys(reducedResult).includes(idx.toString()))
                      setFocused(idx);
                  }}
                  className={`result__preference__choice result__preference__choice--${
                    focused === idx ? 'focused' : 'notfocused'
                  } result__preference__choice--${
                    idx === 1 ? 'centered' : idx === 0 ? 'started' : 'ended'
                  }`}
                >
                  <div
                    className="result__preference__choice__box"
                    style={{
                      cursor:
                        Object.keys(reducedResult).includes(idx.toString()) &&
                        focused !== idx
                          ? 'pointer'
                          : undefined,
                      border:
                        focused === idx
                          ? '1px black solid'
                          : '1px #A4A4A4 solid',
                      color: focused === idx ? 'white' : '#A4A4A4',
                      backgroundColor: focused === idx ? 'black' : 'white',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    {focused === idx ? 'Sélectionée' : 'Sélectioner'}
                  </div>
                </div>
                <div
                  style={{
                    color: idx === 1 ? '#999999' : 'white',
                    fontFamily: 'Roboto Flex',
                    fontSize: '12px',
                    marginTop: '2px'
                  }}
                >
                  Taille idéale
                </div>
              </div>
            ))}
          </div>
          <div className="result__advice">
            {isMobile && (
              <Fits
                style={{
                  height: '100%',
                  width: '60%',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'white'
                }}
              />
            )}
            <div className="result__advice__cut">
              {false && (
                <span className="result__advice__cut__title">
                  {t('cut')}{' '}
                  {t(
                    `comments.${product_id}.cuts.${cuts_template[focused]}.name`
                  )}
                </span>
              )}
              <div className="result__advice__cut__content">
                {initMargin === margins.centers.right && focused === 0
                  ? t('error.small')
                  : initMargin === margins.centers.left && focused === 2
                  ? t('error.big')
                  : ''}
              </div>
            </div>
          </div>
          <div
            className="result__buttons"
            style={{
              width: '100%'
            }}
          >
            <button
              onClick={() => {
                window.parent.postMessage(
                  {
                    action: 'addToCart',
                    variantId: reducedResult[focused].variant_id
                  },
                  '*'
                );
                sendMid();
                closeIframe();
              }}
              className="result__buttons__save"
            >
              <Trans
                ns="components/results/result"
                i18nKey="cart"
                values={{ size: reducedResult[focused]?.label || '' }}
              />
            </button>
            <div className="result__buttons__text">
              {false && (
                <button
                  onClick={() => nextStep()}
                  className="result__buttons__text__products"
                >
                  {t('products')}
                </button>
              )}
              <button
                className="result__buttons__text__restart"
                onClick={restart}
              >
                {t('restart')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
