import { TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import './Text.scss';

interface IPropsText {
  object?: any;
  setDisabled: (e: object) => void;
}

export const Text: FC<IPropsText> = ({ object, setDisabled }) => {
  const [error, setError] = useState<boolean>(false);
  const [value, setValue] = useState<number | undefined>(
    object?.inputRef?.current
  );

  const { t } = useTranslation('components/questions');
  const unit = t(`${object.name}.unit`);

  useEffect(() => {
    setDisabled((prev: object) => ({
      ...prev,
      [object.name]: error || !value
    }));
  }, [error]);

  return (
    <div className="textfield">
      <div className="textfield__title">{t(`${object.name}.title`)}</div>
      <div className="textfield__input">
        <TextField
          value={
            value === undefined
              ? ''
              : unit === 'feet'
              ? `${value?.toString().substring(0, 1)}${
                  value?.toString().slice(1) === '' ? "'‎" : "'"
                }${value?.toString().slice(1)}${
                  value?.toString().slice(1) === '' ? '' : '"‎'
                }`
              : `${value} ${unit}`
          }
          required={true}
          placeholder={t(`${object.name}.placeholder`)}
          inputProps={{
            min: object.min,
            max: object.max,
            style: {
              borderColor: error ? 'red' : 'black',
              textAlign: 'start',
              marginTop: '5px'
            }
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'left'
              }
            }
          }}
          sx={{
            width: '100%',
            '& .MuiInput-underline:after': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '&:hover .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            }
          }}
          onChange={(event) => {
            if (unit === 'feet') {
              const tmpValue =
                event.target.value.match('["\']') !== null &&
                event.target.value.search("'‎[0-9]") === -1 &&
                event.target.value.search(
                  event.target.value.slice(1) === '' ? "'‎" : '"‎'
                ) === -1
                  ? event.target.value.replace(/\D/g, '').slice(0, -1)
                  : parseInt(event.target.value.replace(/\D/g, '').slice(1)) >
                    12
                  ? event.target.value.replace(/\D/g, '').slice(0, -1)
                  : event.target.value.replace(/\D/g, '');
              const newValue = parseInt(tmpValue, 10) || undefined;

              setValue(newValue);

              if (newValue !== undefined) {
                setError(newValue < object.min || newValue > object.max);
              }
              object.inputRef.current = newValue;
            } else {
              const tmpValue =
                event.target.value.match('[a-zA-Z]') !== null &&
                event.target.value.search(unit || '') === -1
                  ? event.target.value.replace(/\D/g, '').slice(0, -1)
                  : event.target.value.replace(/\D/g, '');
              const newValue = parseInt(tmpValue, 10) || undefined;

              setValue(newValue);

              if (newValue !== undefined) {
                setError(newValue < object.min || newValue > object.max);
              }
              object.inputRef.current = newValue;
            }
          }}
          variant="standard"
        />
      </div>
      <div
        className="textfield__error"
        style={{
          opacity: error ? 1 : 0
        }}
      >
        {t(`${object.name}.error`)}
      </div>
    </div>
  );
};
