import { TextField } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import './textinput.scss';

interface IPropsText {
  object?: any;
  setDisabled: (e: object) => void;
}

export const TextInput: FC<IPropsText> = ({ object, setDisabled }) => {
  const [error, setError] = useState<boolean>(false);
  const [value, setValue] = useState<string | number | undefined>(
    object?.inputRef?.current
  );

  const { t } = useTranslation('components/info');

  useEffect(() => {
    setDisabled((prev: object) => ({
      ...prev,
      [object.name]: error || !value
    }));
  }, [error, value]);

  return (
    <div className="textinput">
      <div className="textinput__title">{t(`${object.name}.title`)}</div>
      <div className="textinput__input">
        <TextField
          value={value}
          required={true}
          placeholder={t(`${object.name}.unit`)}
          inputProps={{
            style: {
              borderColor: error ? 'red' : 'black',
              textAlign: 'start',
              marginTop: '5px'
            }
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'left'
              }
            }
          }}
          sx={{
            width: '100%',
            '& .MuiInput-underline:after': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            },
            '&:hover .MuiInput-underline:before': {
              borderBottomColor: error ? 'red' : 'black'
            }
          }}
          onChange={(event) => {
            const value = event.target.value;
            setValue(value);
            if (value !== undefined) {
              if (object.min || object.max) {
                setError(
                  value.length < object.min || value.length > object.max
                );
              } else {
                setError(value.length === 0);
              }
            }
            object.inputRef.current = value;
          }}
          variant="standard"
        />
      </div>
      <div
        className="textinput__error"
        style={{
          opacity: error ? 1 : 0
        }}
      >
        {t(`${object.name}.error`)}
      </div>
    </div>
  );
};
