import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getLocalAndParse } from '../../../../store/localStoreUtils';
import { useUserContext } from '../../../../store/userContext';

import './stepIntro.scss';
import { useMediaQuery } from 'react-responsive';
import { Typography } from '@mui/material';

interface IPropsStepIntroDesktop {
  nextStep: () => void;
  setLocalGender: (value: string) => void;
}

const StepIntroDesktop: FC<IPropsStepIntroDesktop> = ({
  nextStep,
  setLocalGender
}) => {
  const { t } = useTranslation('components/intro');
  const { setUser } = useUserContext() || {};
  const userStored = getLocalAndParse('user');
  const [selectGender, setSelectGender] = useState<string | undefined>(
    undefined
  );
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  const handleNext = (gender: string) => {
    setLocalGender(gender);
    setSelectGender(gender);

    const newUser = {
      ...userStored,
      gender: gender
    };

    localStorage.setItem('user', JSON.stringify(newUser));
    setLocalGender(gender);

    if (setUser) {
      setUser(newUser);
    }

    gender !== undefined && nextStep();
  };

  return (
    <div className="intro-desktop">
      <div className="intro-desktop__container">
        <h1 className="intro-desktop__container__title">
          <Trans
            ns="components/intro"
            i18nKey="desktop.title"
            values={{ separator: '\n' }}
          />
        </h1>
        <p className="intro-desktop__container__description">
          {t('desktop.description')}
        </p>
        <div className="intro-desktop__container__buttons">
          <button
            className={`intro-desktop__container__buttons__button ${
              selectGender === 'male'
                ? 'intro-desktop__container__buttons__button--active'
                : undefined
            }`}
            onClick={() => {
              if (isMobile) {
                handleNext('male');
              } else {
                setLocalGender('male');
                setSelectGender('male');
              }
            }}
          >
            {t('gender.male')}
          </button>
          <button
            className={`intro-desktop__container__buttons__button ${
              selectGender === 'female'
                ? 'intro-desktop__container__buttons__button--active'
                : undefined
            }`}
            onClick={() => {
              if (isMobile) {
                handleNext('female');
              } else {
                setLocalGender('female');
                setSelectGender('female');
              }
            }}
          >
            {t('gender.female')}
          </button>
        </div>
        {!isMobile && (
          <button
            className="intro-desktop__container__buttons__continue"
            disabled={selectGender === undefined}
            onClick={() => handleNext(selectGender || '')}
          >
            {t('continue')}
          </button>
        )}
        <div
          className="questions-desktop__container__content__copyright"
          style={{ bottom: '51px' }}
        >
          <p>
            {t('pp_part1')}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.kleep.ai/legal"
              style={{ textDecoration: 'underline' }}
            >
              {t('pp_link')}
            </a>
          </p>
        </div>
        <div style={{ display: 'flex', position: 'absolute', bottom: '22px' }}>
          <Typography
            fontFamily="Roboto Flex"
            fontSize="10px"
            fontWeight={300}
            sx={{
              opacity: 0.7,
              textAlign: 'center',
              marginBottom: '3px',
              cursor: 'default',
              zIndex: 10
            }}
          >
            Measured by
          </Typography>
          <img
            style={{
              width: '32px',
              marginLeft: '3px',
              marginBottom: '4px',
              height: 'auto',
              zIndex: 10
            }}
            src={require('../../../../assets/logo/kleep.png')}
          />
        </div>
      </div>
    </div>
  );
};

export default StepIntroDesktop;
