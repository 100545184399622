import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArrowBackIcon = (props: SvgIconProps) => (
  <SvgIcon
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.75 6H1.25M1.25 6L6.75 0.5M1.25 6L6.75 11.5" stroke="black" />
  </SvgIcon>
);

export default ArrowBackIcon;
