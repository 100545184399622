import React, { useEffect, useState, useTransition } from 'react';
import { TextInput } from '../molecules/TextInput';
import './userInfo.scss';
import { useTranslation } from 'react-i18next';
import ContinueButton from '../molecules/ContinueButton';
import PrivacyPolicy from '../molecules/PrivacyPolicy';

interface Props {
  object: any;
  nextStep(): void;
  setIsShowAnimation(value: boolean): void;
  isShowAnimation: boolean;
}

const UserInfo: React.FC<Props> = ({
  object,
  nextStep,
  setIsShowAnimation,
  isShowAnimation
}: Props) => {
  const { t } = useTranslation('components/info');

  const [disabled, setDisabled] = useState(
    object.reduce((acc: any, { name }: keyof object) => {
      acc[name] = false;
      return acc;
    }, {})
  );
  const [transformValue, setTransformValue] = useState(
    isShowAnimation ? 'translateX(101%)' : 'translateX(0%)'
  );
  const [opacity, setOpactity] = useState(0);

  useEffect(() => {
    if (isShowAnimation) {
      requestAnimationFrame(() => {
        setTransformValue('translateX(0%)');
      });
      setIsShowAnimation(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpactity(1);
    }, 800);
  }, []);

  return (
    <div
      className="info"
      style={{
        transform: transformValue
      }}
    >
      <div className="info__container" style={{ opacity: opacity }}>
        <div>
          <p className="info__title">{t('title')}</p>
        </div>
        <div className="info__inputs">
          {Object.keys(object).map((idx) => {
            return (
              <TextInput
                key={idx}
                object={object[idx]}
                setDisabled={setDisabled}
              />
            );
          })}
        </div>
        <div>
          <ContinueButton
            onClick={nextStep}
            disabled={Object.values(disabled).some((value) => value === true)}
          />
        </div>
        <div style={{ marginTop: '17px' }}>
          <PrivacyPolicy />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
